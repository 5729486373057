import React, { lazy, Suspense, useEffect } from 'react';
import { Switch, Route, Router, Redirect, useRouteMatch } from 'react-router-dom';

import AppRoute from './containers/app/AppRoute';
import AuthenticationRoute from './containers/authentication/AuthenticationRoute';
import { notification, Spin } from 'antd';
import history from './helpers/history';
import { listAppRoutes, listAuthenticationRoutes } from 'router';
import { ConnectedRouter } from 'connected-react-router';
import NotFound from 'components/NotFound';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { RESET_NOTIFY_STATE } from 'redux/actions/notify';
import { REQUEST_STATE } from 'app-configs';
import AppLayout from 'components/Layout/Layout/AppLayout/AppLayout';
import AuthLayout from 'components/Layout/Layout/AuthLayout/AuthLayout';
import 'antd/dist/antd.css';
import { USER_ROLES } from 'app-configs';
import MulticircleLoading from 'components/atomic/MulticircleLoading/MulticircleLoading';
import FullPageLoading from 'components/Loading/FullPageLoading/FullPageLoading';

console.debug('listAppRoutes =>', listAppRoutes);
console.debug('listAuthenticationRoutes =>', listAuthenticationRoutes);

function App() {
    const dispatch = useDispatch();
    const notify = useSelector((state) => state.notify);
    const userProfile = useSelector((state) => state.user?.profile);
    const initialNavigation = getInitialNavigation();

    function getInitialNavigation() {
        if (userProfile?.roles?.includes(USER_ROLES.UPLOADER)) {
            return '/sign-cert';
        }
        if (userProfile?.roles?.includes(USER_ROLES.PUBLISHER)) {
            return '/sign-cert';
        }
        if (userProfile?.roles?.includes(USER_ROLES.SIGNER)) {
            return '/sign-cert';
        }
        if (userProfile?.roles?.includes(USER_ROLES.LEARNER)) {
            return '/student/list-cert';
        }
        return '/sign-cert';
    }

    useEffect(() => {
        if (notify.requestState === REQUEST_STATE.SUCCESS) {
            notification.success({
                message: 'Thành công',
                description: notify?.description ?? '',
            });
            dispatch(RESET_NOTIFY_STATE());
        } else if (notify.requestState === REQUEST_STATE.ERROR) {
            notification.error({
                message: 'Thất bại',
                description: notify?.description ?? 'Một lỗi đã xảy ra',
            });
            dispatch(RESET_NOTIFY_STATE());
        }
    }, [notify.requestState]);

    return (
        <ConnectedRouter history={history}>
            <Suspense fallback={<FullPageLoading />}>
                <Switch>
                    {listAppRoutes.map(({ path, exactContainer = true }) => (
                        <Route
                            path={path}
                            render={() => (
                                <AppLayout>
                                    <AppRoute />
                                </AppLayout>
                            )}
                            key={path}
                            exact={exactContainer}
                        />
                    ))}
                    {listAuthenticationRoutes.map(({ path, exactContainer = true }) => (
                        <Route
                            path={path}
                            render={() => (
                                <AuthLayout>
                                    <AuthenticationRoute />
                                </AuthLayout>
                            )}
                            key={path}
                            exact={exactContainer}
                        />
                    ))}
                    <Redirect exact from="/" to={initialNavigation} />
                    <Route exact={true} path="/not-found-page">
                        <NotFound />
                    </Route>
                    <Route path="*">
                        <NotFound />
                    </Route>
                </Switch>
            </Suspense>
        </ConnectedRouter>
    );
}

export default App;
