import AppLayout from 'components/Layout/Layout/AppLayout/AppLayout';
import { lazy } from 'react';
import { initModules } from 'router/index';
// import { detailCertStudent } from '..';
export const uploadCertModule = {
    key: 'student',
    path: 'Student',
};

export default {
    path: '/student/list-cert',
    exact: true,
    isPrivate: true,
    layout: AppLayout,
    component: lazy(async () => {
        await initModules([uploadCertModule], 'app');
        return import('./pages/ListCert');
    }),
};

export const childRoutes = [
    {
        path: '/student/verify-cert',
        exact: true,
        isPrivate: false,
        needCheckToken: false,
        // layout: AppLayout,
        component: lazy(async () => {
            await initModules([uploadCertModule], 'app');
            return import('./pages/VerifyCert');
        }),
    },
];
