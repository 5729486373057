import AuthLayout from 'components/Layout/Layout/AuthLayout/AuthLayout';
import { lazy } from 'react';
import { initModules } from 'router';
export const forgetPasswordModule = { key: 'forgetPassword', path: 'Forget' };

export default {
    path: '/auth/forget-password',
    exact: true,
    isPrivate: false,
    layout: AuthLayout,
    component: lazy(async () => {
        await initModules([forgetPasswordModule], 'authentication');
        return import('./ForgetPassword');
    }),
};
