import { REQUEST_STATE } from 'app-configs';
import { combineReducers } from 'redux';
import {
    FILTER_NOTIFICATION,
    FILTER_NOTIFICATION_FAIL,
    FILTER_NOTIFICATION_SUCCESS,
    GET_NOTIFICATION_LIST,
    GET_NOTIFICATION_LIST_FAIL,
    GET_NOTIFICATION_LIST_SUCCESS,
    IS_READ_NOTIFICATION,
    IS_READ_NOTIFICATION_FAIL,
    IS_READ_NOTIFICATION_SUCCESS,
    RESET_FILTER_NOTIFICATION,
    RESET_GET_NOTIFICATION_LIST,
    RESET_IS_READ_NOTIFICATION,
} from '../actions/notification';

const defaultState = {
    state: null,
    data: [],
};

export default combineReducers({
    list: (state = defaultState, action) => {
        switch (action.type) {
            case GET_NOTIFICATION_LIST().type: {
                return {
                    ...state,
                    state: REQUEST_STATE.REQUEST,
                };
            }
            case GET_NOTIFICATION_LIST_SUCCESS().type: {
                const { data } = action.payload;
                return {
                    ...state,
                    data: data,
                    state: REQUEST_STATE.SUCCESS,
                };
            }
            case GET_NOTIFICATION_LIST_FAIL().type: {
                return {
                    ...state,
                    state: REQUEST_STATE.ERROR,
                };
            }
            case RESET_GET_NOTIFICATION_LIST().type: {
                return {
                    ...defaultState,
                };
            }
            case IS_READ_NOTIFICATION_SUCCESS().type: {
                //sau khi đổi trạng thái, cập nhât data, so sánh với data cũ, trùng với id được action thì lưu lại
                const { data } = action.payload;
                const newListNotification = state.data.map((notificationItem) =>
                    notificationItem.id === data.id ? data : notificationItem,
                );
                return {
                    ...state,
                    data: newListNotification,
                    state: REQUEST_STATE.SUCCESS,
                };
            }
            case FILTER_NOTIFICATION_SUCCESS().type: {
                const { data } = action.payload;
                const newListNotification = state.data.map((notificationItem) =>
                    notificationItem.id === data.id ? data : notificationItem,
                );
                return {
                    ...state,
                    data: newListNotification,
                    state: REQUEST_STATE.SUCCESS,
                };
            }
            default:
                return state;
        }
    },
    isRead: (state = defaultState, action) => {
        switch (action.type) {
            case IS_READ_NOTIFICATION().type: {
                return {
                    ...state,
                    state: REQUEST_STATE.REQUEST,
                };
            }
            case IS_READ_NOTIFICATION_SUCCESS().type: {
                const { data } = action.payload;
                return {
                    ...state,
                    data: data,
                    state: REQUEST_STATE.SUCCESS,
                };
            }
            case IS_READ_NOTIFICATION_FAIL().type: {
                return {
                    ...state,
                    state: REQUEST_STATE.ERROR,
                };
            }
            case RESET_IS_READ_NOTIFICATION().type: {
                return {
                    ...defaultState,
                };
            }
            default:
                return state;
        }
    },
    filterNotify: (state = defaultState, action) => {
        switch (action.type) {
            case FILTER_NOTIFICATION().type: {
                return {
                    ...state,
                    state: REQUEST_STATE.REQUEST,
                };
            }
            case FILTER_NOTIFICATION_SUCCESS().type: {
                const { data } = action.payload;
                return {
                    ...state,
                    data: data,
                    state: REQUEST_STATE.SUCCESS,
                };
            }
            case FILTER_NOTIFICATION_FAIL().type: {
                return {
                    ...state,
                    state: REQUEST_STATE.ERROR,
                };
            }
            case RESET_FILTER_NOTIFICATION().type: {
                return {
                    ...defaultState,
                };
            }
            default:
                return state;
        }
    },
});
