export function GET_NOTIFICATION_LIST(payload) {
    return {
        type: 'GET_NOTIFICATION_LIST',
        payload,
    };
}
export function GET_NOTIFICATION_LIST_SUCCESS(payload) {
    return {
        type: 'GET_NOTIFICATION_LIST_SUCCESS',
        payload,
    };
}
export function GET_NOTIFICATION_LIST_FAIL(payload) {
    return {
        type: 'GET_NOTIFICATION_LIST_FAIL',
        payload,
    };
}
export function RESET_GET_NOTIFICATION_LIST(payload) {
    return {
        type: 'RESET_GET_NOTIFICATION_LIST',
        payload,
    };
}
//isRead
export function IS_READ_NOTIFICATION(payload) {
    return {
        type: 'IS_READ_NOTIFICATION',
        payload,
    };
}
export function IS_READ_NOTIFICATION_SUCCESS(payload) {
    return {
        type: 'IS_READ_NOTIFICATION_SUCCESS',
        payload,
    };
}
export function IS_READ_NOTIFICATION_FAIL(payload) {
    return {
        type: 'IS_READ_NOTIFICATION_FAIL',
        payload,
    };
}
export function RESET_IS_READ_NOTIFICATION(payload) {
    return {
        type: 'RESET_IS_READ_NOTIFICATION',
        payload,
    };
}
// filter
export function FILTER_NOTIFICATION(payload) {
    return {
        type: 'FILTER_NOTIFICATION',
        payload,
    };
}
export function FILTER_NOTIFICATION_SUCCESS(payload) {
    return {
        type: 'FILTER_NOTIFICATION_SUCCESS',
        payload,
    };
}
export function FILTER_NOTIFICATION_FAIL(payload) {
    return {
        type: 'FILTER_NOTIFICATION_FAIL',
        payload,
    };
}
export function RESET_FILTER_NOTIFICATION(payload) {
    return {
        type: 'RESET_FILTER_NOTIFICATION',
        payload,
    };
}
