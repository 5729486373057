import { REQUEST_STATE } from 'app-configs';
import { UPLOAD } from 'app-data/fetch';
import { PATCH } from 'app-data/fetch';
import { PUT } from 'app-data/fetch';
import { POST } from 'app-data/fetch';
import { GET } from 'app-data/fetch';

export const apiUploadAvatarIssuer = async (params) => {
    try {
        const response = await POST('/issuer/upload', params, { isFullPath: false });
        return {
            state: REQUEST_STATE.SUCCESS,
            data: response,
        };
    } catch (error) {
        console.log('error', error);
        return {
            error: error,
            state: REQUEST_STATE.ERROR,
            data: {},
        };
    }
};

export const apiGetDetailInstitution = async (params) => {
    try {
        const response = await GET('/institution', params, { isFullPath: false });
        return {
            state: REQUEST_STATE.SUCCESS,
            data: response,
        };
    } catch (error) {
        console.log('error', error);
        return {
            error: error,
            state: REQUEST_STATE.ERROR,
            data: {},
        };
    }
};

export const apiRemindSignFirstTime = async (params) => {
    try {
        const response = await GET('/issuer/email/signing', params, { isFullPath: false });
        return {
            state: REQUEST_STATE.SUCCESS,
            data: response,
        };
    } catch (error) {
        console.log('error', error);
        return {
            error: error,
            state: REQUEST_STATE.ERROR,
            data: {},
        };
    }
};

export const apiUpdateIssuerInfo = async (params) => {
    try {
        const response = await PUT('/issuer/update-info', params, { isFullPath: false });
        return {
            state: REQUEST_STATE.SUCCESS,
            data: response,
        };
    } catch (error) {
        console.log('error', error);
        return {
            error: error,
            state: REQUEST_STATE.ERROR,
            data: {},
        };
    }
};

export const apiUploadIssuerAvatar = async (file) => {
    try {
        const response = await UPLOAD('/issuer/upload-avatar', file, { isFullPath: false }, 'file');
        return {
            state: REQUEST_STATE.SUCCESS,
            data: response,
        };
    } catch (error) {
        console.log('error', error);
        return {
            error: error,
            state: REQUEST_STATE.ERROR,
            data: {},
        };
    }
};

export const apiUploadIssuerSignature = async (file) => {
    try {
        const response = await UPLOAD('/issuer/upload-sign-img', file, { isFullPath: false }, 'file');
        return {
            state: REQUEST_STATE.SUCCESS,
            data: response,
        };
    } catch (error) {
        console.log('error', error);
        return {
            error: error,
            state: REQUEST_STATE.ERROR,
            data: {},
        };
    }
};

export const apiChangeIssuerPassword = async (params) => {
    try {
        const response = await PATCH('/issuer/password', params, { isFullPath: false });
        return {
            state: REQUEST_STATE.SUCCESS,
            data: response,
        };
    } catch (error) {
        console.log('error', error);
        return {
            error: error,
            state: REQUEST_STATE.ERROR,
            data: {},
        };
    }
};
