import React from 'react';
import { MODULES } from 'app-configs';
import { lazy } from 'react';
import { initModules } from 'router/index';
import { detailCertModule } from '../DetailCert/route';

export const uploadCertModule = {
    key: 'signCert',
    path: 'SignCert',
};

export default {
    path: '/sign-cert',
    exact: true,
    isPrivate: true,
    component: lazy(async () => {
        await initModules([uploadCertModule], 'app');
        return import('./pages/SignProgress/SignProgress');
    }),
};

export const childRoutes = [
    {
        path: '/sign-cert/detail/:id',
        exact: true,
        isPrivate: true,
        component: lazy(async () => {
            await initModules([uploadCertModule, detailCertModule], 'app');
            return import('./pages/ListStudentsPerSign/ListStudentsPerSign');
        }),
    },
];
