import { REQUEST_STATE } from 'app-configs';
import { POST } from 'app-data/fetch';
import { GET } from 'app-data/fetch';

export const apiNotification = async (params) => {
    try {
        const response = await GET('/issuer/notification/token', {}, { isFullPath: false });
        return {
            state: REQUEST_STATE.SUCCESS,
            data: response ?? [],
            total: response.length ?? 0,
        };
    } catch (error) {
        console.log('error', error);
        return {
            error: error,
            state: REQUEST_STATE.ERROR,
            data: {},
        };
    }
};

export const isReadNotificationId = async ({ notificationId }) => {
    try {
        const response = await GET(`/notification/${notificationId}`, {}, { isFullPath: false });
        return {
            state: REQUEST_STATE.SUCCESS,
            data: response,
        };
    } catch (error) {
        console.log('error', error);
        return {
            error: error,
            state: REQUEST_STATE.ERROR,
            data: {},
        };
    }
};

export const apiFilterNotification = async (params) => {
    try {
        const response = await POST('/notification/filter', params, { isFullPath: false });
        return {
            state: REQUEST_STATE.SUCCESS,
            data: response ?? [],
        };
    } catch (error) {
        console.log('error', error);
        return {
            error: error,
            state: REQUEST_STATE.ERROR,
            data: {},
        };
    }
};
