export function CLEAR_ALL_STATE(payload) {
    return {
        type: 'CLEAR_ALL_STATE',
        payload,
    };
}

export function AUTHENCATE_USER(payload) {
    return {
        type: 'AUTHENCATE_USER',
        payload,
    };
}

export function UPDATE_ISSUER_INFO(payload) {
    return {
        type: 'UPDATE_ISSUER_INFO',
        payload,
    };
}
export function UPDATE_ISSUER_INFO_SUCCESS(payload) {
    return {
        type: 'UPDATE_ISSUER_INFO_SUCCESS',
        payload,
    };
}
export function UPDATE_ISSUER_INFO_FAIL(payload) {
    return {
        type: 'UPDATE_ISSUER_INFO_FAIL',
        payload,
    };
}
export function RESET_UPDATE_ISSUER_INFO(payload) {
    return {
        type: 'RESET_UPDATE_ISSUER_INFO',
        payload,
    };
}
