export const Configs = {
    BASE_API: process.env.REACT_APP_BASE_API_URL,
    DOMAIN: '',

    HOMEPAGE_ROUTE: '/upload-cert',

    CURRENT_PAGE: 1,
    FILE_MAXIMUM: 5, //MB
    PAGE_SIZE_20: 20,
    PAGE_SIZE_4: 4,
};

export const REQUEST_STATE = {
    ERROR: 'ERROR',
    REQUEST: 'REQUEST',
    SUCCESS: 'SUCCESS',
};

// key store in localStorage, Cookies, Session
export const I18LANGUAGE_KEY = 'i18nextLng';
export const TOKEN_KEY = 'authencation';
export const CONFIG_COLUMNS = 'configColumns';

export const ACTION_TYPE = {
    CREATE: 'CREATE',
    LIST: 'LIST',
    VIEW: 'VIEW',
    DELETE: 'DELETE',
    UPDATE: 'UPDATE',
    UNMOUNT: 'UNMOUNT',
};

export const VALID_IMAGE_TYPES = ['image/gif', 'image/jpeg', 'image/png'];

export const STUDENT_CEFT_STATUS = {
    DATA_UPLOAD: 'DATA_UPLOAD_0',
    PDF_GENERATED: 'PDF_GENERATED_1',
    PENDING: 'PENDING_2',
    NEED_SIGN: 'NEED_SIGN_3',
    SIGN_ERROR: 'SIGN_ERROR_3',
    COMPLETE_SIGN: 'COMPLETE_SIGN_4',
    WRAP: 'WRAP_5',
    UPLOAD_TO_BC: 'UPLOAD_TO_BC_6',
    REVOKED: 'REVOKE_7',
    ERROR_SOME_FILE_SEND_EMAIL: 'ERROR_SOME_FILE_SEND_EMAIL_8',
    UPLOAD_JSON_CERT: 'UPLOAD_JSON_CERT_9',
    SEND_EMAIL: 'SEND_EMAIL_10', // TODO: do dữ liệu cũ, code cập nhật status nên bị lỗi. Tạm thời vá bug như này.
    SEND_EMAIL_FAIL: 'SEND_EMAIL_FAIL_11',
};

export const SIGN_PROVIDER = {
    BSIGN: 'BSIGN',
    VNPT_SIGN: 'VNPT_SIGN',
    VIETTEL_SIGN: 'VIETTEL_SIGN',
};

// 1. UI flow: add router
export const CERT_STATUS = [
    {
        text: 'Chưa ký xong',
        value: [STUDENT_CEFT_STATUS.NEED_SIGN, STUDENT_CEFT_STATUS.PENDING],
    },
    {
        text: 'Đã ký xong',
        value: STUDENT_CEFT_STATUS.COMPLETE_SIGN,
    },
    {
        text: 'Đã tải lên blockchain',
        value: STUDENT_CEFT_STATUS.UPLOAD_TO_BC,
    },
    {
        text: 'Đã gửi email',
        value: STUDENT_CEFT_STATUS.SEND_EMAIL,
    },
];

export const USER_ROLES = {
    SIGNER: 'SIGNER',
    PUBLISHER: 'PUBLISHER',
    UPLOADER: 'UPLOADER',
};

export const NOTIFICATION_TYPES = {
    SIGNER: 'SIGNER',
    PUBLISHER: 'PUBLISHER',
    UPLOADER: 'UPLOADER',
};

export const ADDRESS_OWNER_MANAGEMENT = {
    56: '0xfBb4F8AFa108B4A3B1AD2C80aD77045611f5be86',
    1: '',
    11155111: '0x89dA91Ec007E203846804E3A4e0d6C81e61dff03',
};

export const LINKED_ID_ADDRESS = {
    11155111: '0xd71d363dc3225b40cbf0dd1777265e6dce962dbd',
    56: '0x962a26e0f53fF05Dc4E2f75F47aFAEB7384081aF',
};

export const IPFS_GATEWAY = 'https://beige-glad-sailfish-825.mypinata.cloud/ipfs';

export const NETWORK_CONFIG = {
    56: {
        chainId: 56,
        chainName: 'Binance Smart Chain',
        nativeCurrency: {
            name: 'BNB',
            symbol: 'BNB',
            decimals: 18,
        },
        rpcUrls: ['https://bitter-responsive-tent.bsc.discover.quiknode.pro/c4c66566289c3353f77e19e921e2d3240a490aa6/'],
        blockExplorerUrls: ['https://bscscan.com'],
    },
    1: {
        chainId: 1,
        chainName: 'Ethereum Mainnet',
        nativeCurrency: {
            name: 'ETH',
            symbol: 'ETH',
            decimals: 18,
        },
        rpcUrls: ['https://eth.llamarpc.com'],
        blockExplorerUrls: ['https://etherscan.io'],
    },
    11155111: {
        chainId: 11155111,
        chainName: 'Sepolia Testnet',
        nativeCurrency: {
            name: 'ETH',
            symbol: 'ETH',
            decimals: 18,
        },
        rpcUrls: ['https://rpc.sepolia.org'],
        blockExplorerUrls: ['https://sepolia.etherscan.io'],
    },
};
