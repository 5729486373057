import AuthLayout from 'components/Layout/Layout/AuthLayout/AuthLayout';
import { lazy } from 'react';
import { initModules } from 'router';
export const loginModule = { key: 'login', path: 'Login' };

export default {
    path: '/auth/login',
    exact: true,
    isPrivate: false,
    layout: AuthLayout,
    component: lazy(async () => {
        return import('.');
    }),
};
