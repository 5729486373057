import MulticircleLoading from '../../atomic/MulticircleLoading/MulticircleLoading';
import classNames from 'classnames/bind';
import styles from './FullPageLoading.module.sass';

let cx = classNames.bind(styles);

function FullPageLoading({ opacity = 0.8 }) {
    return (
        <div className={cx('full-page-loading')} style={{ backgroundColor: `rgba(255, 255, 255, ${opacity})` }}>
            <MulticircleLoading />
        </div>
    );
}

export default FullPageLoading;
