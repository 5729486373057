import AuthLayout from 'components/Layout/Layout/AuthLayout/AuthLayout';
import { lazy } from 'react';
import { initModules } from 'router';
import { forgetPasswordModule } from '../Forget/route';
export const changePasswordModule = { key: 'changePassword', path: 'Change' };

export default {
    path: '/auth/change-password',
    exact: true,
    isPrivate: false,
    layout: AuthLayout,
    component: lazy(async () => {
        await initModules([changePasswordModule, forgetPasswordModule], 'authentication');
        return import('./ChangePassword');
    }),
};
