import { REQUEST_STATE } from 'app-configs';
import { isReadNotificationId } from 'app-data/notification';
import { apiFilterNotification } from 'app-data/notification';
import { apiNotification } from 'app-data/notification';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
    FILTER_NOTIFICATION,
    FILTER_NOTIFICATION_FAIL,
    FILTER_NOTIFICATION_SUCCESS,
    GET_NOTIFICATION_LIST,
    GET_NOTIFICATION_LIST_FAIL,
    GET_NOTIFICATION_LIST_SUCCESS,
    IS_READ_NOTIFICATION,
    IS_READ_NOTIFICATION_FAIL,
    IS_READ_NOTIFICATION_SUCCESS,
} from '../actions/notification';

function* getNotificationList({ type, payload }) {
    try {
        const response = yield call(apiNotification);

        if (response.state === REQUEST_STATE.SUCCESS) {
            yield put(
                GET_NOTIFICATION_LIST_SUCCESS({
                    data: response?.data,
                }),
            );
        } else {
            yield put(GET_NOTIFICATION_LIST_FAIL());
        }
    } catch (error) {}
}

function* isReadNotification({ type, payload }) {
    const { notificationId } = payload;
    try {
        const response = yield call(isReadNotificationId, { notificationId });
        if (response.state === REQUEST_STATE.SUCCESS) {
            yield put(
                IS_READ_NOTIFICATION_SUCCESS({
                    data: response?.data,
                }),
            );
        } else {
            yield put(IS_READ_NOTIFICATION_FAIL());
        }
    } catch (error) {}
}
function* filterNotification({ type, payload }) {
    const { isRead } = payload;
    try {
        //truen vao 1 object call(api, {})
        const response = yield call(apiFilterNotification, { isRead });
        if (response.state === REQUEST_STATE.SUCCESS) {
            yield put(
                FILTER_NOTIFICATION_SUCCESS({
                    data: response?.data,
                }),
            );
        } else {
            yield put(FILTER_NOTIFICATION_FAIL());
        }
    } catch (error) {
        console.log('error: ', error);
    }
}

export default function* () {
    yield takeLatest(GET_NOTIFICATION_LIST().type, getNotificationList);
    yield takeLatest(IS_READ_NOTIFICATION().type, isReadNotification);
    yield takeLatest(FILTER_NOTIFICATION().type, filterNotification);
}
