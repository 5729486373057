import React from 'react';
import { MODULES } from 'app-configs';
import { lazy } from 'react';
import { initModules } from 'router/index';
import AuthLayout from 'components/Layout/Layout/AuthLayout/AuthLayout';

export const detailCertModule = {
    key: 'detailCert',
    path: 'DetailCert',
};

export default {
    path: '/detail-cert/:ceftId',
    exact: true,
    isPrivate: false,
    needCheckToken: false,
    component: lazy(async () => {
        await initModules([detailCertModule], 'app');
        return import('./components/DetailCert/DetailCert');
    }),
};

export const childRoutes = [
    {
        path: '/verify',
        exact: true,
        isPrivate: false,
        needCheckToken: false,
        component: lazy(async () => {
            await initModules([detailCertModule], 'app');
            return import('./components/VerifyJson/VerifyJson');
        }),
    },
];
