import Navigation from 'components/Layout/Navigation/Navigation';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FILTER_NOTIFICATION } from 'redux/actions/notification';
import './AppLayout.sass';
function AppLayout({ children }) {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(
            FILTER_NOTIFICATION({
                isRead: false,
            }),
        );
    }, []);
    return (
        <div className="app-layout">
            <Navigation />
            {children}
        </div>
    );
}

export default AppLayout;
