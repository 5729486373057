import { call, put, takeLatest } from '@redux-saga/core/effects';
import { TOKEN_KEY } from 'app-configs';
import { REQUEST_STATE } from 'app-configs';
import { apiProfile } from 'app-data/auth';
import { apiLogin } from 'app-data/auth';
import { apiUploadIssuerAvatar } from 'app-data/users';
import { apiUploadIssuerSignature } from 'app-data/users';
import { apiUpdateIssuerInfo } from 'app-data/users';
import Cookies from 'js-cookie';
import { UPDATE_ISSUER_INFO_FAIL } from 'redux/actions';
import { UPDATE_ISSUER_INFO_SUCCESS } from 'redux/actions';
import { UPDATE_ISSUER_INFO } from 'redux/actions';
import { NOTIFY_SUCCESS } from 'redux/actions/notify';
import { NOTIFY_ERROR } from 'redux/actions/notify';
import { CHECK_VALID_TOKEN } from 'redux/actions/user';
import { CHECK_VALID_TOKEN_SUCCESS } from 'redux/actions/user';
import { CHECK_VALID_TOKEN_FAIL } from 'redux/actions/user';
import { LOGIN_SUCCESS } from 'redux/actions/user';
import { LOGIN_FAIL } from 'redux/actions/user';
import { LOGIN } from 'redux/actions/user';

function* handleLogin({ type, payload }) {
    try {
        const response = yield call(apiLogin, payload);
        if (response.state === REQUEST_STATE.SUCCESS) {
            Cookies.set(TOKEN_KEY, response.data.accessToken);
            const profile = yield call(apiProfile);
            yield put(LOGIN_SUCCESS(profile.data));
        } else {
            yield put(LOGIN_FAIL());
        }
    } catch (error) {
        console.log('error: ', error);
    }
}

function* checkValidToken({ type, payload }) {
    try {
        const response = yield call(apiProfile);
        if (response.state === REQUEST_STATE.SUCCESS) {
            yield put(
                CHECK_VALID_TOKEN_SUCCESS({
                    data: response?.data,
                }),
            );
        } else {
            Cookies.remove(TOKEN_KEY);
            yield put(CHECK_VALID_TOKEN_FAIL());
        }
    } catch (error) {
        console.log('error: ', error);
    }
}

function* updateIssuerInfo({ type, payload }) {
    const { userInfo } = payload;
    try {
        if (userInfo?.avatar?.file?.originFileObj) {
            const updateAvatarResponse = yield call(apiUploadIssuerAvatar, userInfo?.avatar?.file?.originFileObj);

            if (updateAvatarResponse.state === REQUEST_STATE.ERROR) {
                yield put(
                    NOTIFY_ERROR({
                        description: 'Tải lên avatar thất bại',
                    }),
                );
            }
        }

        if (userInfo?.signatureImage?.file?.originFileObj) {
            const updateSignatureResponse = yield call(
                apiUploadIssuerSignature,
                userInfo?.signatureImage?.file?.originFileObj,
            );
            if (updateSignatureResponse.state === REQUEST_STATE.ERROR) {
                yield put(
                    NOTIFY_ERROR({
                        description: 'Tải lên ảnh chữ ký thất bại',
                    }),
                );
            }
        }

        const updateIssuerInfo = yield call(apiUpdateIssuerInfo, userInfo);
        if (updateIssuerInfo.state === REQUEST_STATE.SUCCESS) {
            yield put(
                UPDATE_ISSUER_INFO_SUCCESS({
                    data: updateIssuerInfo?.data,
                }),
            );
            yield put(
                NOTIFY_SUCCESS({
                    description: 'Cập nhật thông tin thành công',
                }),
            );
        } else {
            yield put(UPDATE_ISSUER_INFO_FAIL());
            yield put(
                NOTIFY_ERROR({
                    description: 'Cập nhật thông tin thất bại',
                }),
            );
        }
    } catch (error) {
        console.log('error: ', error);
    }
}

export default function* userSaga() {
    yield takeLatest(LOGIN().type, handleLogin);
    yield takeLatest(CHECK_VALID_TOKEN().type, checkValidToken);
    yield takeLatest(UPDATE_ISSUER_INFO().type, updateIssuerInfo);
}
